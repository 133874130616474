
import { toast } from "react-toastify";

export const api = async (method: string, endpoint: string, body?: any) => {
  const url = `${process.env.REACT_APP_DOMIAN}${endpoint}`;
  const headers = { "Content-Type": "application/json" ,apitoken: `${sessionStorage.getItem("api_token")}`};
  const res = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });
  const data = await res.json();
  if (data.error && data.error === "Permission denied" && data.status === 401) {

      toast.error(
        <p className="text-white tx-16 mb-0">
          Your session has expired. Please login again.
        </p>,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 2000,
          theme: "colored",
        }
      );

      sessionStorage.removeItem("api_token");
      setTimeout(() => { window.location.href = "/login"; }, 3000);
      return false;
  }
  return data;

};
