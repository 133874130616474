import { ConversionTable } from '../../Data/TablesData/ConverstionData'
import { useEffect, useState } from 'react';
import { FormControl, InputGroup, Row, Col, Card, Collapse, Form, Button, Dropdown, SplitButton } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './conversion.scss'
import { toast } from 'react-toastify';
import { api } from "../../services/api";

const Conversion = () => {


  const [isCreatebtnClicked, setCreatebtnClicked] = useState(false);
  const [collStatusstring, setCollStatusstring] = useState("Active");
  const [expanded, setExpanded] = useState(false);
  const [conversionType, setConversionType] = useState("Only");
  const [fetchStatus, setFetchStatus] = useState(true);
  const [search, setSearch] = useState({ search: '' });
  const [formData, setFormData] = useState({
    conversion_key: 0,
    conversion_name: "",
    description: "",
    external_id: "",
    eligibility_type: "Only",
    eligibility_value: 1,
    status: "ACTIVE"
  });

  const handleCollStatusstring = (e) => {
    if (e.target.checked) {
      setCollStatusstring("Active");
      setFormData({ ...formData, status: 'ACTIVE' })
    }
    else {
      setCollStatusstring("Paused");
      setFormData({ ...formData, status: 'DEACTIVE' })
    }
  }

  const handleExpandClick = () => {
    setExpanded(expanded => !expanded);
    setCreatebtnClicked(!isCreatebtnClicked);
    setFormData({ ...formData, conversion_key: 0, conversion_name: "", description: "", external_id: "", eligibility_type: "Only", eligibility_value: 1, status: "ACTIVE" });
    setCollStatusstring("Active");
  };

  const saveConversion = () => {
    if (formData.conversion_name !== "" || formData.description !== "") {
      let upperCaseConversionType = formData.eligibility_type.toUpperCase();
      const postData = {
        conversion_name: formData.conversion_name,
        description: formData.description,
        external_id: formData.external_id,
        eligibility_type: upperCaseConversionType,
        eligibility_value: formData.eligibility_value,
        status: formData.status
      }

      if (formData.conversion_key === 0) {
        const data = api("POST", "conversion", postData);
        data.then((data) => {
          if (data.status) {
            toast.success(
              <p className="text-white tx-16 mb-0">
                Conversion created successfully
              </p>,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 2000,
                theme: "colored",
              }
            );
            setFetchStatus(true);
            setExpanded(false);
            setCreatebtnClicked(false);
          } else {
            toast.error(
              <p className="text-white tx-16 mb-0"> {data.message}</p>,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 2000,
                theme: "colored",
              }
            );
          }
          setFormData({
            ...formData,
            conversion_key: 0,
            conversion_name: "",
            description: "",
            external_id: "",
            eligibility_type: "Only",
            eligibility_value: 1,
            status: "ACTIVE",
          });
        });

      } else {
        const data = api("PUT", "conversion/" + formData.conversion_key, postData);
        data.then((data) => {
          if (data.status) {
            toast.success(
              <p className="text-white tx-16 mb-0">
                Conversion updated successfully
              </p>,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 2000,
                theme: "colored",
              }
            );
            setFetchStatus(true);
            setExpanded(false);
            setCreatebtnClicked(false);
          } else {
            toast.error(
              <p className="text-white tx-16 mb-0"> {data.message}</p>,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 2000,
                theme: "colored",
              }
            );
          }
          setFormData({
            ...formData,
            conversion_key: 0,
            conversion_name: "",
            description: "",
            external_id: "",
            eligibility_type: "Only",
            eligibility_value: 1,
            status: "ACTIVE",
          });
        });
      }

    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      formData.conversion_name === "" && toast.error(<p className="text-white tx-16 mb-0"> Please enter name of conversion </p>, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000, theme: 'colored' });
      formData.description === "" && toast.error(<p className="text-white tx-16 mb-0"> Please enter description of conversion </p>, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000, theme: 'colored' });
    }
  }

  const handleChange = (e) => {

    if (e.target.name === "eligibility_value") {
      if (e.target.value === "" || !isNaN(e.target.value)) {
        setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

  };

  const handleEdit = (conversion_key) => {
    if (!expanded) {
      setExpanded(true);
      setCreatebtnClicked(true);
    }
    const data = api("GET", "conversion/" + conversion_key);
    data.then((data) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setFormData(data.data.conversion[0]);
      const eligibility_type =
        data.data.conversion[0].eligibility_type.toLowerCase();
      const eligibility_type_ =
        eligibility_type[0].toUpperCase() + eligibility_type.slice(1);
      setConversionType(eligibility_type_);
      setCollStatusstring(
        data.data.conversion[0].status === "ACTIVE" ? "Active" : "Paused"
      );
      setFetchStatus(false);
    });
  }

  function dec(el) {
    let unit = el.currentTarget.parentElement.querySelector("input").value;
    if (Number(unit) === 0) {
      return false;
    } else {
      const val = --el.currentTarget.parentElement.querySelector("input").value;
      setFormData({ ...formData, eligibility_value: val })
    }
  }

  function inc(el) {
    const val = ++el.currentTarget.parentElement.querySelector("input").value;
    setFormData({ ...formData, eligibility_value: val })
  }

  const handleconvBtn = (e) => {
    setConversionType(e)
    setFormData({ ...formData, eligibility_type: e })
  }

  const handleDelete = (conversion_key) => {
    const data = api("DELETE", "conversion/" + conversion_key);
    data.then((data) => {
      if (data.status) {
        toast.success(
          <p className="text-white tx-16 mb-0">
            Conversion deleted successfully
          </p>,
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 2000,
            theme: "colored",
          }
        );
        setFetchStatus(true);
      } else {
        toast.error(
          <p className="text-white tx-16 mb-0"> {data.message}</p>,
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 2000,
            theme: "colored",
          }
        );
      }
    });
  }

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });

    if (value.trim() === "") {
      setFetchStatus(true);
    } else {
      setExpanded(false);
      setCreatebtnClicked(false);
    }
  }

  return (
    <div>
      <div className="page-header cust-pageHeader">
        <h1 className="page-title">Conversion</h1>
        <div className='d-flex justify-content-end align-center custSearchbar'>
          <InputGroup className="w-auto ">
            <FormControl type="text" className="border-end-0 cust-collection-searchBar " placeholder="Search ..." onChange={handleSearchChange} name="search" value={search.search} autoComplete="off" />
            <InputGroup.Text className="bg-white border-start-0 text-muted custconversionformbtns" >
              <i className="fe fe-search" aria-hidden="true"></i>
            </InputGroup.Text>
          </InputGroup>
          <Link className={`btn btn-${isCreatebtnClicked ? "default" : "success"}  ms-2 cust-createCollectionbtn`} onClick={handleExpandClick} to={''} >
            <i className={isCreatebtnClicked ? "fe fe-minus-circle custaddicon me-1" : "fe fe-plus-circle custaddicon me-1"} ></i>
            Create new conversion
          </Link>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Collapse in={expanded} >
            <Card className="custFormTbalegap">
              <Card.Body className="custtablecardbod " >
                <Form.Group>
                  <Form.Label>Name of conversion <span className="text-danger">*</span></Form.Label>
                  <Row>
                    <Col sm={4} className='mb-2'>
                      <Form.Control type="text" aria-label="collection-name" name="conversion_name" value={formData.conversion_name} onChange={handleChange} />
                    </Col>
                    <Col sm={8} className='mb-2 d-flex justify-content-end mt-2 '>
                      <Form.Group>
                        <label className="custom-switch form-switch  mb-3">
                          <input type="checkbox" name="status" className="custom-switch-input " onChange={handleCollStatusstring} {...(collStatusstring === "Active" ? { checked: true } : { checked: false })} />
                          <span className="custom-switch-indicator custom-switch-indicator-md"></span>
                          <span className="custom-switch-description">{collStatusstring}</span>
                        </label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label>Text to display on control device <span className="text-danger">*</span></Form.Label>
                    <Col sm={12} className='mb-2'>
                      <Form.Control as="textarea" rows={3} placeholder="Please give one free bubble tea of client's choice" aria-label="collection-name" name="description" value={formData.description} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row className='cust-conversion-elig' >
                    <Col className='mb-2'>
                      <Form.Label>Eligibilty <span className="text-danger">*</span></Form.Label>

                      <div className="d-flex">                      <div className="handle-counter justify-content-start" id="handleCounter4">
                        <Button variant='default' className="counter-minus lh-2 shadow-none cust-incdcr-btn" onClick={dec}><i className="fa fa-minus text-muted"></i></Button>
                        <input type="text" className="qty cust-incdcr-input" name="eligibility_value" value={formData.eligibility_value} onChange={handleChange} />
                        <Button variant='default' className="counter-plus lh-2 shadow-none cust-incdcr-btn" onClick={inc}><i className="fa fa-plus text-muted"></i></Button>
                        <p style={{ paddingRight: '60px' }}>Time(s)</p>
                      </div>
                        <SplitButton variant="outline-default" title={conversionType} className="default-pils" onSelect={(e) => handleconvBtn(e)} style={{ marginLeft: '-30px' }} >
                          <Dropdown.Item eventKey="Only">Only</Dropdown.Item>
                          <Dropdown.Item eventKey="Day">Per day</Dropdown.Item>
                          <Dropdown.Item eventKey="Week">Per week</Dropdown.Item>
                          <Dropdown.Item eventKey="Month">Per a month </Dropdown.Item>
                          <Dropdown.Item eventKey="Year">Per a year</Dropdown.Item>
                        </SplitButton>
                      </div>
                    </Col>
                  </Row>
                  <Row className='externalId' style={{ maxWidth: "83%" }}>
                    <Form.Label>External ID</Form.Label>
                    <Col sm={5} className='mb-2'>
                      <Form.Control type="text" aria-label="conversion-name" name="external_id" value={formData.external_id} onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className='mb-2 mt-5 mx-auto d-flex justify-content-end'>
                      <Link className="btn btn-success ms-2" to={'#'} onClick={saveConversion}> Save coversion </Link>
                    </Col>
                  </Row>
                </Form.Group>
              </Card.Body>
            </Card>
          </Collapse>
          <Card className="conversion-cust">
            <div className="table-responsive cust-collectionTable">
              <ConversionTable handleEdit={handleEdit} fetchStatusState={fetchStatus} setFetchStatus={setFetchStatus} handleDelete={handleDelete} searchValue={search.search} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Conversion