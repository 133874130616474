import React, { Fragment, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./Layouts/Loader/Loader";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Conversion from "./components/Conversion/Conversion";
import { WagmiConfig } from 'wagmi';
import { client } from "./wagmi";

const App = lazy(() => import("./components/app"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const Operation = lazy(() => import("./components/Operation/Operation"));
const Collection = lazy(() => import("./components/Collection/Collection"));
const Credit = lazy(() => import("./components/Credit/Credit"));
const Device = lazy(() => import("./components/Device/Device"));
const Login = lazy(() => import("./components/Login/Login"));
const Selectwallet = lazy(() => import("./components/Login/Selectwallet"));
const SignUp = lazy(() => import("./components/Login/SignUp"));
const ForgotPassword = lazy(() => import("./components/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/Login/ResetPassword"));
const AuthenticationPage = lazy(
  () => import("./components/AuthenticationPage")
);

const container: HTMLElement | any = document.getElementById("root");
const root = createRoot(container);

root.render(
  <WagmiConfig client={client}>
    <React.StrictMode>
      <Fragment>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            <Routes>
              {document.location.hostname != process.env.REACT_APP_WALLET_DOMAIN ? <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
                <Route
                  path={`${process.env.PUBLIC_URL}/`}
                  element={<Dashboard />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/operation`}
                  element={<Operation />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/collection`}
                  element={<Collection />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/conversion`}
                  element={<Conversion />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/device`}
                  element={<Device />}
                />
              </Route> : ""}
              <Route
                path={`${process.env.PUBLIC_URL}/`}
                element={<AuthenticationPage />}
              >
                {document.location.hostname == process.env.REACT_APP_WALLET_DOMAIN ? <Route
                  path={`${process.env.PUBLIC_URL}/`}
                  element={<Selectwallet />}
                /> : ""}
                <Route
                  path={`${process.env.PUBLIC_URL}/login`}
                  element={<Login />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/signUp`}
                  element={<SignUp />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/forgotPassword`}
                  element={<ForgotPassword />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/resetPassword`}
                  element={<ResetPassword />}
                />
              </Route>
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </Fragment>
    </React.StrictMode>
  </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
