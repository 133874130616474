import React, { FC } from 'react';
import styles from './Loader.module.scss';
import loader from '../../assets/logos/loader.gif';
interface LoaderProps {}

const Loader: FC<LoaderProps> = () => (
  <div className={styles.Loader}>
    <div id="global-loader">
        <img src={loader} alt="loader" className="loader-img cust-loader1" />
    </div>
  </div>
);

export default Loader;
