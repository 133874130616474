import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import loader from '../../assets/logos/loader.gif';
import { api } from '../../services/api';


interface ChildProps {
    fetchStatusState: boolean;
    setFetchStatus: Function;
    handleDelete: Function;
    handleEdit: Function;
    searchValue: string;
}

interface conversion {
    conversion_key: number;
    conversion_name: string;
    created_on: string;
    status: string;
    Action: string;
}

export const ConversionTable = (props: ChildProps) => {
    const { fetchStatusState, setFetchStatus, handleDelete, handleEdit, searchValue } = props;
    const [conversion, setConversion] = useState<conversion[]>([]);
    const [isloading, setIsloading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const fetchData = async (page, size = perPage) =>{
            const data = await api('GET', `conversion?offset=${page}&limit=${size}`);
            setTotalRows(data.pagination.total);
            setConversion(data.data.conversion)
            setFetchStatus(false);
            setIsloading(false);
        }
        if (fetchStatusState) {
            fetchData(offset);
        }
    }, [fetchStatusState])

    const handleDeleteAlert = (conversion_key: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(conversion_key);
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
    }

    useEffect(() => {
        if (searchValue !== "") {
            setIsloading(true);
            const data = api('GET', 'conversion' + '?conversion_name=' + searchValue + '%' )
            data.then((data) => {
                setConversion(data.data.conversion)
                setIsloading(false);
            })
        }
    }, [searchValue])

    const handlePageChange = (page) => {
      setFetchStatus(true);
      setOffset(page - 1);
    };

    const columns: any = [
        {
            name: "Conversion",
            selector: row => [row.conversion_name],
            sortable: true,
            cell: row => <span className="align-middle first-column"> {row.conversion_name} </span>
        },
        {
            name: "Created On",
            selector: row => [row.created_on],
            sortable: true,
            cell: row => <span className="align-middle"> {
                row.created_on.split("T")[0].split("-").reverse().join("-")
            } </span>
        },
        {
            name: "Status",
            sortable: false,
            cell: row =>
                <OverlayTrigger placement="top" overlay={<Tooltip >{row.status}</Tooltip>}>
                    {
                        row.status === "ACTIVE" ? <Badge bg="success-transparent rounded-pill text-success p-2 px-3" className="text-center align-middle">{row.status}</Badge> : <Badge bg="danger-transparent rounded-pill text-danger p-2 px-3" className="text-center align-middle">{row.status}</Badge>
                    }
                </OverlayTrigger>
        },
        {
            name: "",
            sortable: false,
            cell: row => <div className={`cust-actionBtn${row.conversion_key}  align-middle `} hidden   >
                <OverlayTrigger placement="top" overlay={<Tooltip >Edit</Tooltip>}>
                    <Link to={''} className="btn btn-outline-primary cust-option-btn " onClick={() => handleEdit(row.conversion_key)}>
                        <span className="fe fe-edit fs-15 text-primary custconversionformbtns" ></span>
                    </Link>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip >Delete</Tooltip>}>
                    <Link to={''} className="btn btn-outline-danger cust-option-btn ms-2"onClick={() => handleDeleteAlert(row.conversion_key)} >
                        <span className="fe fe-trash-2 text-danger fs-15 custconversionformbtns" ></span>
                    </Link>
                </OverlayTrigger>
            </div>
        },
    ]

    return (
        <div className="product cust-conversion-table">
            {isloading && <div className="d-flex justify-content-center align-items-center cust-loader-table">
                <img src={loader} alt="loader" />
            </div>}
            <DataTable className={`border-bottom ${isloading ? "cust-conversion-table-blur" : ""}`}
                columns={columns}
                data={conversion}
                noHeader
                sortIcon={<ArrowDownwardIcon />}
                defaultSortAsc={true}
                highlightOnHover
                dense
                striped
                onRowMouseEnter={(row) => {
                    document.getElementsByClassName(`cust-actionBtn${row.conversion_key}`)[0].removeAttribute('hidden');
                }}
                onRowMouseLeave={(row) => {
                    document.getElementsByClassName(`cust-actionBtn${row.conversion_key}`)[0].setAttribute('hidden', 'true');
                }}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage === 0 ? 1 : currentPage}
                onChangePage={handlePageChange}
                paginationPerPage={perPage}
            />
        </div>
    );
}